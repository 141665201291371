import { getMemberPrivacySettings as getMemberPrivacySettingsAmbassador } from '@wix/ambassador-members-v1-member-privacy-settings/http';
import { Candidates } from '@wix/ambassador-members-v1-member-privacy-settings/types';

import type { WithErrorHandlerFn } from '../types';
import type { HttpClient } from '../types/controller';

export const getIsPublicMemberCandidateNoOne = async (
  httpClient: HttpClient,
  withErrorHandler: WithErrorHandlerFn,
) => {
  const {
    data: { memberPrivacySettings },
  } = await withErrorHandler(
    () => httpClient.request(getMemberPrivacySettingsAmbassador({})),
    {
      errorCodesMap: {},
    },
  );

  return memberPrivacySettings?.publicMemberCandidates === Candidates.NO_ONE;
};
