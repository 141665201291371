import type {
  InjectedGlobalSettings,
  PublicMember,
  RolesMap,
  WithErrorHandlerFn,
} from '../types';
import type { HttpClient } from '../types/controller';

type ProfileCardMiddlewareServiceOptions = {
  isEditor: boolean;
  httpClient: HttpClient;
  withErrorHandler: WithErrorHandlerFn;
};

export class ProfileCardMiddlewareService {
  private readonly isEditor: boolean;
  private readonly httpClient: HttpClient;
  private readonly withErrorHandler: WithErrorHandlerFn;

  constructor({
    isEditor,
    httpClient,
    withErrorHandler,
  }: ProfileCardMiddlewareServiceOptions) {
    this.isEditor = isEditor;
    this.httpClient = httpClient;
    this.withErrorHandler = withErrorHandler;
  }

  async getMember(uid: string) {
    const { data } = await this.withErrorHandler(
      () =>
        this.httpClient.get<PublicMember>(
          `/_serverless/profile-card-middleware/member/${uid}`,
        ),
      {
        errorCodesMap: {},
      },
    );

    return data;
  }

  async getRolesMap() {
    const { data } = await this.withErrorHandler(
      () =>
        this.httpClient.get<RolesMap>(
          `/_serverless/profile-card-middleware/roles-map`,
        ),
      {
        errorCodesMap: {},
      },
    );

    return data;
  }

  async getGlobalSettings() {
    const { data } = await this.withErrorHandler(
      () =>
        this.httpClient.get<InjectedGlobalSettings>(
          `/_serverless/profile-card-middleware/global-settings`,
          { params: { isEditor: this.isEditor ? 'true' : 'false' } },
        ),
      {
        errorCodesMap: {},
      },
    );

    return data;
  }
}
