import type { BadgeType } from '@wix/members-badge-lib';

import type { WithErrorHandlerFn } from '../../types';
import type { HttpClient } from '../../types/controller';

interface BadgeListResponse {
  badges: BadgeType[];
}

type BadgesServiceOptions = {
  baseUrl: string;
  httpClient: HttpClient;
  withErrorHandler: WithErrorHandlerFn;
};

export class BadgesService {
  private readonly baseUrl: string;
  private readonly httpClient: HttpClient;
  private readonly withErrorHandler: WithErrorHandlerFn;

  constructor({ baseUrl, httpClient, withErrorHandler }: BadgesServiceOptions) {
    this.baseUrl = baseUrl;
    this.httpClient = httpClient;
    this.withErrorHandler = withErrorHandler;
  }

  readonly getBadgeList = async () => {
    const url = `${this.baseUrl}/badges`;

    const { data } = await this.withErrorHandler(
      () => this.httpClient.get<BadgeListResponse>(url),
      {
        errorCodesMap: {},
      },
    );

    return data.badges;
  };
}
