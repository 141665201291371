import type { GetResolvedErrorFn } from '../types';

export const withErrorBiReport = async <T>(
  fn: () => Promise<T>,
  getResolvedError: GetResolvedErrorFn,
) => {
  try {
    const response = await fn();
    return response;
  } catch (error) {
    // ErrorHandler's getResolvedError sends BI event visible in Grafana dashboard
    const { httpError } = getResolvedError(error);
    return Promise.reject(httpError || error);
  }
};
