import { blockMember } from '@wix/ambassador-members-v1-member-to-member-block/http';
import type { TFunction } from '@wix/yoshi-flow-editor';

import type { WithErrorHandlerFn } from '../../types';
import type { HttpClient } from '../../types/controller';

type BlockMemberServiceOptions = {
  httpClient: HttpClient;
  withErrorHandler: WithErrorHandlerFn;
  t: TFunction;
};

const getBlockedMemberApplicationErrors = (
  memberName: string,
  t: TFunction,
) => ({
  MEMBER_ALREADY_BLOCKED: () => ({
    message: t(
      'MemberRoles.action_set.block_member.error.member-already-blocked',
      {
        memberName,
      },
    ),
  }),
  SELF_BLOCKING_FORBIDDEN: () => ({
    message: t(
      'MemberRoles.action_set.block_member.error.self-blocking-forbidden',
    ),
  }),
  ADMIN_BLOCKING_FORBIDDEN: () => ({
    message: t(
      'MemberRoles.action_set.block_member.error.admin-blocking-forbidden',
    ),
  }),
});

export class BlockMemberService {
  private readonly httpClient: HttpClient;
  private readonly withErrorHandler: WithErrorHandlerFn;
  private readonly t: TFunction;

  constructor(options: BlockMemberServiceOptions) {
    this.httpClient = options.httpClient;
    this.withErrorHandler = options.withErrorHandler;
    this.t = options.t;
  }

  async blockMember(memberId: string, memberName: string) {
    return this.withErrorHandler(
      () => this.httpClient.request(blockMember({ memberId })),
      {
        errorCodesMap: {
          applicationError: getBlockedMemberApplicationErrors(
            memberName,
            this.t,
          ),
        },
      },
    );
  }
}
