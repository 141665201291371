import { actionButtonClicked } from '@wix/bi-logger-members-app-uou/v2';
import type { RoleId } from '@wix/members-domain-ts';

import { Experiment } from '../../../constants/experiments';
import { ToastSkin } from '../../../constants/toast';
import { getCommonBIEventProps } from '../../../services/bi-event';
import {
  openMemberBlockNotification,
  openModalWithCallback,
} from '../../../services/modal';
import { maybeNavigateToHomePage } from '../../../services/navigation';
import { Applications } from '../../../services/public-api-store';
import type { ThunkWithArgs } from '../../../types';
import { Origin } from '../../../types';
import { getShowToastAction } from '../../actions';

export const memberBlockMember: ThunkWithArgs<RoleId> =
  (roleId) => async (dispatch, getState, extra) => {
    const {
      compId,
      wixCodeApi,
      platformAPIs,
      experiments,
      getPublicAPI,
      biLogger,
      flowAPI,
      metaData,
    } = extra;

    const state = getState();
    const { viewed } = state.users;
    const { uid, name: memberName } = viewed;
    const membersAreaAPI = await getPublicAPI(Applications.MembersArea);

    const onConfirm = async () => {
      const { blockMemberService } = extra;

      const showNewNotifications = flowAPI.experiments.enabled(
        Experiment.ShowNewMemberBlockNotifications,
      );

      try {
        await blockMemberService.blockMember(uid, memberName);
      } catch (error) {
        const { message } = flowAPI.errorHandler.getResolvedError(error);

        return showNewNotifications
          ? dispatch(
              getShowToastAction({
                message,
                skin: ToastSkin.error,
              }),
            )
          : undefined;
      }

      biLogger?.report(
        actionButtonClicked({
          ...getCommonBIEventProps(flowAPI, state, metaData),
          action_type: 'block_confirm',
          member_chosen: uid,
          formOrigin: Origin.Profile,
          site_member_id: state.users.current?.uid,
        }),
      );

      if (state.site.isWidgetPlugin) {
        return membersAreaAPI?.openBlockedMemberEmptyState();
      }

      maybeNavigateToHomePage(wixCodeApi);

      if (showNewNotifications) {
        return dispatch(
          getShowToastAction({
            message: flowAPI.translations.t(
              'MemberRoles.action_set.block_member.success',
              {
                memberName,
              },
            ),
          }),
        );
      }

      return openMemberBlockNotification({
        compId,
        wixCodeApi,
        isMobile: flowAPI.environment.isMobile,
        experiments,
        queryParams: { memberName },
      });
    };

    openModalWithCallback({
      compId,
      modalType: roleId,
      payload: { memberName: viewed.name },
      platformAPIs,
      wixCodeApi,
      experiments,
      onConfirm,
    });
  };
